import React from 'react';
import styled from 'styled-components';
import {
  Body2,
  Description,
  H1,
  H3,
  P,
  Subtitle5,
} from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import viewport1Img from '../../assets/img/productInfoManagementVp1.png';
import viewport4Img from '../../assets/img/productInfoManagementVp4.png';
import viewport5Img from '../../assets/img/productInfoManagementVp5.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import { ProductInformationManagementPageContent } from '@/pages/product-information-management';
import StepBlueCheckCard from '@/components/StepBlueCheckCard';
import BigPurpleCard from '@/components/BigPurpleCard';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background: linear-gradient(#ffffff, #e7edf3);
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-bottom: 1rem;
  }
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const StyledDescription = styled(Description)`
  :first-of-type {
    margin-top: 1rem;
  }
`;

const ViewPortsWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const StepsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const IntegrationsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  gap: 18px;
`;

const IntegrationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #fff;
  background: #f2f4f7;
  padding: 15px;
  height: calc(130px - 15px * 2);
`;

const IntImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const OtherIntegrations = styled(IntegrationContainer)`
  border: 1px solid #dbe3eb;
  background: #fff;
`;

const AdditionalWrapper = styled.div`
  border-radius: 20px;
  background-color: #101c20;
`;

const FourthViewPort = styled.div<{ image: string }>`
  ${({ image }) =>
    image &&
    `background-image: ${`url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;`}
  border-radius: 20px;
  padding: 69px 40% 82px 130px;
  display: flex;
  flex-direction: column;
`;

const Button = styled(ButtonDefault)`
  padding: 15px 30px;
  width: calc(166px - 30px * 2);
  border: 1px solid #adb5bd;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  background-color: transparent;
  transition: 0.4s;
  &:hover {
    background-color: #dbe3eb4e;
  }
`;

const ViewFifthGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const VP5Image = styled.img`
  object-fit: contain;
`;

interface ProductInformationManagementProps {
  content: ProductInformationManagementPageContent;
}

const ProductInformationManagementContainer: React.FunctionComponent<
  ProductInformationManagementProps
> = ({
  content: { viewport1, viewport2, viewport3, viewport4, viewport5, viewport6 },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport img={viewport1Img}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
          {viewport1.title3}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H3 style={{ maxWidth: 768 }}>{viewport2.title}</H3>
      <StepsWrapper>
        {viewport2.carouselItems.map((item, i) => (
          <StepBlueCheckCard
            title={item.title}
            description={item.description}
            step={i + 1}
          />
        ))}
      </StepsWrapper>
    </SecondViewport>

    <ViewPortsWrapper>
      <DefaultViewport withoutPaddingBottom>
        <H3 style={{ marginBottom: `0.7rem` }}>{viewport3.sectionTitle}</H3>
        <P style={{ marginBottom: `1.87rem` }}>
          {viewport3.sectionDescription}
        </P>
        <Subtitle5 style={{ fontWeight: 600, marginBottom: `1.5rem` }}>
          {viewport3.integrationsTitle}
        </Subtitle5>
        <IntegrationsWrapper>
          {viewport3.integrationsItems.map((int) => (
            <IntegrationContainer>
              <IntImg src={int.image} />
            </IntegrationContainer>
          ))}
          <OtherIntegrations>
            <Body2>{viewport3.otherIntegrationsCount}</Body2>
          </OtherIntegrations>
        </IntegrationsWrapper>
      </DefaultViewport>

      <DefaultViewport withoutPaddingTop withoutPaddingBottom>
        <AdditionalWrapper>
          <FourthViewPort image={viewport4Img}>
            <Subtitle5
              style={{ marginBottom: `1.5rem` }}
              className="accent-text-green-bold"
            >
              {viewport4.cardLabel}
            </Subtitle5>
            <H3 style={{ color: `#FFF`, marginBottom: `1.5rem` }}>
              {viewport4.cardText}
            </H3>
            <Button to={viewport4.button.url}>{viewport4.button.label}</Button>
          </FourthViewPort>
        </AdditionalWrapper>
      </DefaultViewport>
      <DefaultViewport withoutPaddingBottom withoutPaddingTop>
        <ViewFifthGrid>
          <Column>
            <H3 style={{ fontSize: `24px` }}>{viewport5.title}</H3>
            <Body2>{viewport5.accentText}</Body2>
            <P>{viewport5.description1}</P>
            <P>{viewport5.description2}</P>
          </Column>
          <Column>
            <VP5Image src={viewport5Img} />
          </Column>
        </ViewFifthGrid>
      </DefaultViewport>

      <DefaultViewport withoutPaddingTop>
        <BigPurpleCard
          title={viewport6.title}
          leftColumn={viewport6.leftColumn}
          rightColumn={viewport6.rightColumn}
          bottomText={viewport6.bottomText}
          button={viewport6.button}
        />
      </DefaultViewport>
    </ViewPortsWrapper>
  </>
);

export default ProductInformationManagementContainer;
